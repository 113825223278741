<template>
  <div>
    <h1>
      <slot name="otp-code-title">
        {{ $t('otp.enterCode') }}
      </slot>
    </h1>
    <p>
      <slot name="otp-code-detail">
        {{ $t(`otp.codeDetail.${ contact.name }`, { masked: contact.masked }) }}
      </slot>
    </p>
    <p v-if="expires">
      <slot name="otp-code-expires">
        {{ $t('otp.enterCodeExpires', { expires }) }}
      </slot>
    </p>
    <div class="otp-container" v-show="!isBusy">
      <v-otp-input
          ref="otpInputComponent"
          input-type="number"
          input-classes="otp-input"
          separator=""
          :num-inputs="6"
          :should-auto-focus="true"
          @on-change="onCodeChanged"
          @on-complete="onCodeEntered"
      />
    </div>
    <p class="otp-error" v-if="errorMessage">{{ errorMessage }}</p>
    <p class="resend" v-if="!isBusy">
      {{ $t('otp.didNotReceive') }} <a class="purple-link" @click="onResendCode()">{{ $t('otp.resendCode') }}</a>
    </p>
    <div class="busy-container" v-show="isBusy && busyMessage">
      <a-spinner size="medium" /> {{ busyMessage }}
    </div>
  </div>
</template>

<script>

export default {
  name: "OtpCode",

  beforeMount() {
    this.contact = JSON.parse(this.contactJson);
  },
  data() {
    return {
      isBusy: false,
      contact: null,
      busyMessage: null,
      errorMessage: null,
    }
  },
  props: {
    expires: {  // This is only for display purposes. It's not used in the code.
      type: String,
      required: false,
      default: null
    },
    user: {
      type: String,
      required: false,
    },
    contactJson: {
      type: String,
      required: true,
    },
  },
  methods: {
    setBusy(message) {
      this.isBusy = true;
      this.busyMessage = message;
      this.errorMessage = null;
    },
    clearBusy() {
      this.isBusy = false;
      this.busyMessage = null;
    },
    clearInput() {
      this.$refs.otpInputComponent.clearInput();
    },
    onCodeChanged(value) {
      this.$emit("code-changed", value);
    },
    onCodeEntered(value) {
      this.$emit("code-entered", value);
    },
    onResendCode() {
      this.$emit("resend-code", this.contact);
    },
  }
}
</script>

<style lang="scss" scoped>
h1 {
  padding-top: 16px;
  padding-bottom: 24px;
  font-size: 18px;
  font-weight: 600;
}
.disabled {
  transition: all 0.1s ease-out;
  opacity: 0.3;
}
.resend {
  margin-top: 16px;
  margin-bottom: 16px;
  a {
    cursor: pointer;
  }
}
.busy-container
{
  margin-top: 8px;
  margin-bottom: 8px;
  vertical-align: middle;
  .a-spinner {
    vertical-align: middle;
  }
}
.otp-error {
  margin-top: 16px;
  margin-bottom: 16px;
  color: red;
}
.otp-container {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 5px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  &.error {
    border: 1px solid red !important;
  }
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number].otp-input {
  -moz-appearance: textfield;
}
</style>