import AppsignalInstance from "../utils/Appsignal";

function errorHandler(appsignal, app) {
  return function (error, vm, info) {
    const componentName = vm.$vnode
      ? vm.$vnode.componentOptions.tag // Vue 2
      : vm.$options.name // Vue 3

    // Convert string errors to Error class, if dev tries `throw 'error happened!'`
    if (typeof error === 'string' || error instanceof String) {
      error = new Error(error)
    }

    const span = appsignal.createSpan()

    span
      .setAction(componentName || "[unknown Vue component]")
      .setTags({ framework: "Vue", info, version: app?.version ? app?.version : '' })
      .setError(error)
      .setNamespace("Vue")

    appsignal.send(span)

    if (typeof console !== "undefined" && typeof console.error === "function") {
      console.error(error)
    }
  }
}

export default function configureVueErrorHandler(VueInstance) {
  const appsignalInstance = AppsignalInstance();
  if (appsignalInstance) {
    VueInstance.config.errorHandler = errorHandler(appsignalInstance, VueInstance);
  }
}