import OtpForm from './OtpForm';
import Notify from "./Notify";
import SignIn from "./SignIn";

export default (Vue) => {
  return () => {
    const signInContainer = document.getElementById('login_vue_container');
    if (!signInContainer) {
      console.warn("No login container found")
      return;
    }
    return new Vue({
      el: "#login_vue_container",
      data() {
        return {
          emailValue: '',
          passwordValue: '',
          passwordConfirmValue: '',
          showValidationMessage: false,
        };
      },
      components: {
        'sign-in': SignIn,
        'otp-form': OtpForm,
        notify: Notify,
      },
      mounted() {
        this.$clients.initializeClients();
        this.displayLoginForm();
      },
      methods: {
        displayLoginForm () {
          const loginLoading = document.getElementById('login-form-loading');
          const loginContainer = document.getElementById('login-form-container');

          if(loginLoading) {
            loginLoading.classList.add("display-none")
          }

          if(loginContainer) {
            loginContainer.classList.remove("display-none")
          }
        }
      },
    });
  }
}
