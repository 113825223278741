<template>
  <div class="verify-contact">
    <otp-code
        ref="otpCode"
        :contact-json="contact"
        user="user"
        @code-entered="handleCodeEntered"
        @resend-code="handleResendCode"
    ></otp-code>
  </div>
</template>

<script>
import OtpCode from "./OtpCode"
import { OtpService } from "../services/OtpService";
import { ContactMethodOtpClient } from "../services/ContactMethodOtpClient";
export default {
  name: "VerifyContact",
  components: {
    OtpCode
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
    contact: {
      type: String,
      required: true,
    },
    formId: {
      type: String,
      required: true,
      default: 'verify-contact-form',
    },
    inputCode: {
      type: String,
      required: true,
      default: 'code',
    },
    inputUserId: {
      type: String,
      required: true,
      default: 'id',
    },
  },
  beforeMount() {
    this.otpService = new OtpService( new ContactMethodOtpClient( this.$clients.oauth ) );
    this.contactMethod = JSON.parse(this.contact);
  },
  methods: {
    setBusy(message) {
      this.$refs.otpCode.setBusy(message);
    },
    reset() {
      this.$refs.otpCode.clearBusy();
      this.$refs.otpCode.clearInput();
    },
    handleCodeEntered(code) {
      this.setBusy(this.$t('otp.verifying'));
      /**
       * Until we move auth to a Nuxt-like frontend,
       * this component will simple pass info to the Rails form
       * and submit it.
       */
      this.setInputValue(this.inputUserId, this.userId);
      this.setInputValue(this.inputCode, code);
      this.submitForm();
    },
    handleResendCode() {
      this.setBusy();
      this.otpService
          .requestNewOtp(this.userId, this.contactMethod.name)
          .then(() => {
            this.$notify({
              group: "default",
              type: 'info',
              duration: 2000,
              title: this.$t('otp.newCodeSent.' + this.contactMethod.name, { masked: this.contactMethod.masked }),
            });
          })
          .catch((error) => {
            this.$notify({
              type: 'error',
              duration: 2000,
              title: this.$t('otp.errorSendingCode.' + this.contactMethod.name, { masked: this.contactMethod.masked }),
            });
          })
          .finally(() => this.reset());
    },
    setInputValue(id, value) {
      const input = document.querySelector(`#${id}`)
      input.value = value
    },
    submitForm() {
      const form = document.querySelector(`#${this.formId}`);
      form.submit();
    },
  }
}

</script>
<style lang="scss" scoped>
.verify-contact {
  max-width: 380px;
}
</style>
