import Vue from "vue";
import Vuelidate from 'vuelidate';

import OtpInput from "@bachdgvn/vue-otp-input";
import SignInUsernameAdapter from "../components/Guardianships/SignInUsernameAdapter";
import SignIn from "../components/SignIn";

import VerifyContactRoot from "../components/VerifyContactRoot";
import SignInRoot from "../components/SignInRoot";

import api from '../plugins/api';

import Base from '@apptegy/base';
import Containers from '@apptegy/containers';
import Forms from '@apptegy/forms';
import Navigation from '@apptegy/navigation';
import Icons from '@apptegy/icons';
import Notification from '@apptegy/notification';
import Pagination from '@apptegy/pagination';
import Table from '@apptegy/table';
import { VueIntl } from '@apptegy/vue-intl';

import en from '../locale/en.json';
import es from '../locale/es.json';

import formsLocale from '@apptegy/forms/locale/en.json'
import notificationLocale from '@apptegy/notification/locale/en.json'
import baseLocale from '@apptegy/base/locale/en.json'
import containersLocale from '@apptegy/containers/locale/en.json';
import navigationLocale from '@apptegy/navigation/locale/en.json';

import configureVueErrorHandler from "../utils/ConfigueVueErrorHandler";
import Appsignal from "../utils/Appsignal";

Vue.use(Base);
Vue.use(Containers);
Vue.use(Forms);
Vue.use(Navigation);
Vue.use(Icons);
Vue.use(Notification);
Vue.use(Pagination);
Vue.use(Table);
Vue.use(api);
Vue.use(Vuelidate);
Vue.use(VueIntl, {
  defaultLocale: 'en',
  locales: [
    {
      code: 'en',
      messages: {
        ...en,
        dscl: { ...notificationLocale, ...baseLocale, ...formsLocale, ...containersLocale, ...navigationLocale },
      },
    },
    {
      code: 'es',
      messages: es
    },
  ],
});
Vue.component('v-otp-input', OtpInput);
Vue.component('sign-in-username-adapter', SignInUsernameAdapter);
Vue.component('sign-in', SignIn);

/**
 * Legacy entrypoint for application
 * @todo: Move remaining Vue code from ERB into .vue only files. Then eliminate this section.
 */
document.addEventListener('DOMContentLoaded', () => {
  const APPSIGNAL_ERROR_MONITORING_API_KEY = document.getElementById("appsignal-key")?.value || null;
  Appsignal(APPSIGNAL_ERROR_MONITORING_API_KEY);
  configureVueErrorHandler(Vue)
  SignInRoot(Vue)()
  VerifyContactRoot(Vue)()
})