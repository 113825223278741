
import Notify from "./Notify";
import VerifyContact from "./VerifyContact";

export default (Vue) => {
  return () => {
    const signInContainer = document.getElementById('verify_vue_container');
    if (!signInContainer) {
      return;
    }

    return new Vue({
      el: "#verify_vue_container",
      components: {
        notify: Notify,
        'verify-contact': VerifyContact,
      },
      data() {
        return {}
      },
      mounted() {
        this.$clients.initializeClients();
      },
    });
  }
}
